import React from 'react';
import ReactDOM from 'react-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Button, Paper } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#1e1138',
      paper: '#5d4d80',
    },
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    allVariants: {
      color: 'white',
    },
  },
});

function App() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh',  }}>
      <Paper>
        <Container maxWidth='sm'>
          <Typography variant='h2' component='h2' style={{ marginTop: 5 }}>
            <code>chipdesign.io</code>
          </Typography>
        </Container>
      </Paper>

      <Container maxWidth='sm' style={{ marginTop: 50 }}>
        <Typography variant='body1'>
          More content coming soon, for now this is just an extremely busted pre-alpha version.
          <br />
          {/* Button */}
          <Button
            style={{ marginTop: 10 }}
            variant='contained'
            href='https://chipdesign.io/editor'
          >Editor</Button>
        </Typography>
      </Container>
    </Box>
  );
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
);
